<template>
  <div class="checklist-page">
    <n-loader :loading="$var('load')" />
    <div class="container">
      <div class="wrap-title">
        <div class="title">Чек-листы</div>
      </div>
    </div>
    <div class="wrap">
      <c-empty v-if="checklists.length <= 0" title="У вас нет задач." />
      <div v-for="item in checklists" v-else :key="item.id" class="checklist" @click="$router.push({name: 'checklist', params:{id: item.id}})">
        <div class="left">{{ item.title }}</div>
        <div class="right">
          <n-icon icon="backRow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Index',
  data() {
    return {
      checklists: [],
      user: $app.auth.user(),
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.checklists.get(this.user.companyId, this.user.id).then((response) => {
        this.checklists = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  padding: 55px 15px 0 15px;
}

.checklist-page {
  padding-top: 50px;

  .wrap-title {
    text-align: center;
    position: fixed;
    background-color: #F5F5FA;
    left: 0;
    height: 44px;
    right: 0;
    top: calc(env(safe-area-inset-top) + 100px);
  }

  .empty {
    text-align: center;
    font-size: 1.5em;
    color: #ababab;
  }

  .title {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 700;
    color: #000000;
    font-size: 16px;
  }
  .wrap {
    margin-top: 44px;
    .checklist {
      margin-bottom: 12px;
      background-color: #BDBDBD1A;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      padding: 12px 30px;
      &:last-child {
        margin-bottom: 0;
      }
      .left {
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }
      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(180deg);
        ::v-deep {
          path {
            fill: #2C117C;
          }
        }
      }
    }
  }

}
</style>
